import { version } from './version';

export const environment = {
  BASE_URL: 'https://dterioclaro.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3543907',
  schema: 'pbi_dte_rioclaro',
  groupId: '2e108bca-1376-4e20-b936-afa7f87886e5',
  analiseGeral: '11f8fb53-228a-4570-8c20-9892a4444538',
  analiseUsuarios: '85367031-876e-4dd3-9a98-47852658319a',
  analiseComunicados: '0c0cd914-2c9f-4b66-aec4-cb7db0d387ca',
  analiseProcurador: 'a54adc14-b960-4de4-9bf9-7b504ead2158',
  analiseEventos: 'c416a55a-2863-422f-83c9-cdbfb4ae730a'
};
